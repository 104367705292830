import { put } from "redux-saga/effects";

export function success<T,K = never>(type:string, payload?:T, request?:{payload:K}){
    const obj = {type, payload, requestParams: typeof request?.payload === 'object' ? {...request?.payload} : {payload: request?.payload }};
    if(obj.payload){
        obj.payload = sanitizePayload(obj.payload);
    }
    return put(obj);
}

export function error<T>(type:string, error:any, request?:{payload:T}, ){
    console.log(error)
    if(typeof error === 'object' && typeof error?.data?.message === 'string'){
        error = error.data.message;
    }
    if(typeof error === 'object' && typeof error.message === 'string'){
        error = error.message;
    }
    if(typeof error === 'string'){
        error = error.replace('execution reverted: ', '');
    }
    return put({type, error, requestParams: request ? request.payload : undefined});
}

function sanitizePayload<T>(payload?:T){
    if(!payload){
        return;
    }
    // we need to remove any null values otherwise they get mapped to empty objects {}.
    const recursiveSanitize = (payload:any) => {
        for (const key in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, key)) {
                const element = payload[key];
                if(typeof element === "object"){
                    if(element == null){
                        delete payload[key];
                    }else{
                        recursiveSanitize(element);
                    }
                }
            }
        }
        return payload;
    }
    return recursiveSanitize(payload) as T;
}
