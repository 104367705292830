export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const MINT_PENDING = "MINT_PENDING";
export const MINT_SUCCESS = "MINT_SUCCESS";
export const MINT_FAILURE = "MINT_FAILURE";

export const SWITCH_TO_CORRECT_NETWORK_PENDING = "SWITCH_TO_CORRECT_NETWORK_PENDING";
export const SWITCH_TO_CORRECT_NETWORK_SUCCESS = "SWITCH_TO_CORRECT_NETWORK_SUCCESS";
export const SWITCH_TO_CORRECT_NETWORK_FAILURE = "SWITCH_TO_CORRECT_NETWORK_FAILURE";

export const GET_MY_ETHER_BALANCE_PENDING = "GET_MY_ETHER_BALANCE_PENDING";
export const GET_MY_ETHER_BALANCE_SUCCESS = "GET_MY_ETHER_BALANCE_SUCCESS";
export const GET_MY_ETHER_BALANCE_FAILURE = "GET_MY_ETHER_BALANCE_FAILURE";

export const GET_MY_FLIP_COUNT_PENDING = "GET_MY_FLIP_COUNT_PENDING";
export const GET_MY_FLIP_COUNT_SUCCESS = "GET_MY_FLIP_COUNT_SUCCESS";
export const GET_MY_FLIP_COUNT_FAILURE = "GET_MY_FLIP_COUNT_FAILURE";

export const GET_MY_FLIPS_PENDING = "GET_MY_FLIPS_PENDING";
export const GET_MY_FLIPS_SUCCESS = "GET_MY_FLIPS_SUCCESS";
export const GET_MY_FLIPS_FAILURE = "GET_MY_FLIPS_FAILURE";

export const GET_TOKEN_METADATA_PENDING = "GET_TOKEN_METADATA_PENDING";
export const GET_TOKEN_METADATA_SUCCESS = "GET_TOKEN_METADATA_SUCCESS";
export const GET_TOKEN_METADATA_FAILURE = "GET_TOKEN_METADATA_FAILURE";

export const PRESENT_ALERT = 'PRESENT_ALERT'
export const DISMISS_TOPMOST_ALERT = 'DISMISS_TOPMOST_ALERT'
export const SUBMIT_ALERT_INPUT = 'SUBMIT_ALERT_INPUT'
export const LOGOUT = 'LOGOUT'
export const UPDATE_GLOBAL_MINT_PROGRESS = "UPDATE_GLOBAL_MINT_PROGRESS";
