import { Provider } from "react-redux";
import { Persistor, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "state/configure";

export function StateWrapper(props:{children:any}){
    const persistor: Persistor = persistStore(store);
    return (<Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
            {props.children}
        {/* </PersistGate> */}
    </Provider>)
}