export function pathFriendlyName(pathName:string){
    if(pathName.startsWith('/')){
        pathName = pathName.substring(1);
    }
    pathName = pathName.toLowerCase();
    if(!pathName.length){
        return null;
    }else if(pathName === 'mint'){
        return 'Mint NFTs';
    }else if(pathName === 'collection'){
        return 'All Collection';
    }else if(pathName === 'inventory'){
        return 'My Inventory'
    }

    return null;
}