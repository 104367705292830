import { alert } from "utils/ui/alert";

let _log = '';


export function log(...args:any){
    console.log(...args);
    const date = (new Date()).toUTCString();
    _log += `[${date}] ${args.map((arg:any) => typeof arg === 'object' ? JSON.stringify(arg) : arg)}\n`;
}

export function copyLog(){
    navigator.clipboard.writeText(_log);
    alert({
        title: 'Transaction Log Copied',
        description: 'Transaction log copied. This log only contains data that is deemed to be public, you can share it with The Flip team for troubleshooting purposes. Note that it does NOT contain anything sensitive and The Flip team will NEVER ask for your private key or recovery phrase.'
    })
}