import { Footer } from 'components/Footer';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'state/configure';
import { Routes } from 'components/Routes';
import { StateWrapper } from 'components/wrappers/StateWrapper';
import Initializer from 'components/wrappers/Initializer';
import Alert from 'components/Alert';
import Header from 'components/Header';
import 'core/App.css';
import 'core/Animations.css';
import 'core/Vars.css';

function App() {
  return (
    <StateWrapper>
        <ConnectedRouter history={history}>
            <Initializer>
                <Header />
                <main>
                    <Routes />
                </main>
                <Footer />
                <Alert />
            </Initializer>
        </ConnectedRouter>
    </StateWrapper>
  );
}

export default App;
