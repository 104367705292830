import { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import styles from './Mint.module.css';
import { mint } from 'state/actions';
import LargeButton from 'components/buttons/LargeButton';
import GlobalMintProgress from 'components/web3/GlobalMintProgress';
import { isPrivateSaleInProgress, isPublicSaleInProgress, isRevealed } from 'interop/web3/ethereum';
import { alert } from 'utils/ui/alert';
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";
import { DataWithMeta } from 'types/state';
import { myFlipCountSelector, walletAddressSelector } from 'state/selectors';
import { Buffer } from "buffer";
import RequireEthereum from 'components/wrappers/RequireEthereum';


type MintProps = { 
    mintState?:MintState,
    myAddress?:DataWithMeta<string>;
    myFlipCount:number
}

type MintDispatchProps = { 
    mint: (amount:number, merkleProof?:string[]) => void,
}

type MintState = { 
    amount:number,
    mintState:'not-started'|'private-sale'|'public-sale'|'finished',
    hashes?:string[],
    isMinting:boolean
}

class Mint extends Component<MintProps & MintDispatchProps, MintState>{

    constructor(props:MintProps){
        super(props as any);
        this.state = {
            amount: 1,
            mintState: 'not-started',
            isMinting: false
        }
    }

    async componentDidMount(){
        const isPublicSale = await isPublicSaleInProgress();
        const isPrivateSale = await isPrivateSaleInProgress();
        const isFinished = await isRevealed();
        console.log('is public sale?', isPublicSale);
        console.log('is private sale?', isPrivateSale);
        if(!isPublicSale && !isPrivateSale){
            if(isFinished){
                alert('Sale has ended.');
            }else{
                alert('Sale not started yet.');

            }
        }
        if(isPublicSale){
            this.setState({
                mintState: 'public-sale'
            })
        }else if(isPrivateSale){
            this.setState({
                mintState: 'private-sale'
            });
            const response = await fetch('/data/hashes.txt');
            const rawText = await response.text();
            const hashes = rawText.split('\n');
            this.setState({
                hashes
            })
        }else if(isFinished){
            this.setState({
                mintState: 'finished'
            })
        }
        window.Buffer = window.Buffer || Buffer;
    }

    componentDidUpdate(prevProps:MintProps){
        if(prevProps.myFlipCount != this.props.myFlipCount){
            if(this.state.isMinting){
                alert({
                    title: 'Congratulations',
                    description: 'You have successfully minted your Flips!',
                    actions: [
                        {
                            title: 'Go to My Inventory',
                            onPress: () => {
                                window.location.href = 'https://trove.treasure.lol/collection/flip-project?tab=owned_tokens'
                            }
                        },
                        {
                            title: 'Okay',
                            onPress: () => {
                            }
                        },
                    ]
                });
                this.setState({
                    isMinting: false
                });
            }
        }
    }

    get enabled(){
        if(this.state.mintState == 'public-sale'){
            return true;
        }
        console.log('enabled', this.state.mintState, this.state.hashes)
        if(this.state.mintState == 'private-sale'){
            return !!this.state.hashes;
        }
        return false;
    }


    mint(){
        if(this.state.mintState == 'private-sale'){
            const myAddressKeccak256 = keccak256(this.props.myAddress?.data!);
            const leafNodes = this.state.hashes!;
            const merkleTree = new MerkleTree (leafNodes, keccak256, { sortPairs: true });
            const merkleProof = merkleTree.getHexProof(myAddressKeccak256);
            if(!merkleProof?.length){
                alert('Your address is not whitelisted for private sale.');
                return;
            }
            this.setState({
                isMinting: true
            });
            this.props.mint(this.state.amount, merkleProof);

        }else{
            this.setState({
                isMinting: true
            });
            this.props.mint(this.state.amount);
        }
    }

    render(){
        return <div className={styles['container']}>
            <RequireEthereum>
                <div className={`${styles['inner-container']} flex-row-compact`}>
                    <img alt='NFTs' className={styles['nfts']} src="/images/nfts/nfts.gif"></img>
                    <div className={styles['mint-options']}>
                        <select className={styles['mint-value-select']} onChange={e => this.setState({amount: parseInt(e.currentTarget.value)})}>
                            <option value="1">&nbsp;1</option>
                            <option value="2">&nbsp;2</option>
                            {
                                this.state.mintState == 'public-sale' && <>
                                    <option value="5">&nbsp;5</option>
                                    <option value="10">&nbsp;10</option>
                                    <option value="20">&nbsp;20</option>
                                    <option value="50">&nbsp;50</option>
                                </>
                            }
                        
                        </select>
                        <LargeButton disabled={!this.enabled} className={styles['mint-button']} onClick={() => this.mint()}>Mint</LargeButton>
                        <span className={styles["mint-price"]}>Price: 0.03 ETH / Flip NFT</span>
                        {/* <Link className='inline-link' to={'/inventory'}>Already Minted? Click Here</Link> */}
                    </div>
                </div>
                <div className={styles['mint-progress-container']}>
                    <GlobalMintProgress />
                </div>
            </RequireEthereum>
          
        </div>
        // <div class="minting">
        //     <div class="minting-indicator">
        //         <img class="spinner" src='/images/assets/spinner.png'>
        //         <span id="minting-text">Minting 2 Flips...<br>Please wait</span>
        //         <br>
        //     </div>
        //     <sub id="minting-detail"></sub>
        // </div>
        // <div class="inventory">
        //     <h3>Loading...</h3>
        //     <div class="inventory-flip-list">
        //         <img class="spinner" src='/images/assets/spinner.png'>
        //     </div>
        // </div>
    }

}


function mapStateToProps(state:any, ownProps:Partial<MintProps>) : MintProps {
    return {
        myAddress: walletAddressSelector(state),
        myFlipCount: myFlipCountSelector(state).data ?? 0

    }
}

function mapDispatchToProps(dispatch:Dispatch) : MintDispatchProps {
    return {
        mint: (amount, merkleProof) => dispatch(mint(amount, merkleProof)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mint);