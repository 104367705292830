export const CONTRACT_ABI = [
    "function balanceOf(address owner) view returns (uint)",
    "function transfer(address to, uint amount)",
    "event Transfer(address indexed from, address indexed to, uint amount)",
    "function mintWhitelistNFTs(uint count, bytes32[] calldata merkleProof) public payable",
    "function mintNFTs(uint count) public payable",
    "function tokensOfOwner(address _owner) external view returns (uint[] memory)",
    "function tokenURI(uint256 tokenId) public view returns (string memory) ",
    "function isPublicSaleInProgress() public view returns (bool)",
    "function isPrivateSaleInProgress() public view returns (bool)",
    "function isRevealed() public view returns (bool)",
    "function isAgedWine(uint tokenId) public view returns (bool)",
    "function isDiamondHands(uint tokenId) public view returns (bool)",
    "function isAbstractPeriodStarted() public view returns (bool)",
    "function currentHodlingPeriod(uint tokenId) public view returns (uint)",
    "function longestHodlingPeriod(uint tokenId) public view returns (uint)",
    "function categoryIndexForToken(uint tokenId) public view returns (uint)",
];

export const CHAIN_ID = 42161;
export const CONTRACT_ADDRESS = '0x31D2678Ed8Ea62fC63d5ECfF713920F75167D062';

export const PRICE_PER_TOKEN_IN_ETHER = 0.03;