import { ConnectedRouter } from 'connected-react-router';
import Calculator from 'pages/Calculator';
import Mint from 'pages/Mint';
import { Welcome } from 'pages/Welcome';
import { Route, Switch } from 'react-router';
import { history } from 'state/configure';

export function Routes(){
    return <Switch>
        <Route path='/' render={Welcome} exact />
        <Route path='/mint' render={() => <Mint />} />
        <Route path='/collection' render={Welcome} />
        <Route path='/inventory' render={Welcome} />
        <Route path='/calculator' render={Calculator} />
    </Switch>
}
