import { registerListeningGlobalMintProgress, unregisterListeningGlobalMintProgress } from 'interop/web3/ethereum';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { globalMintProgressSelector } from 'state/selectors';
import { DataWithMeta } from 'types/state';
import styles from './GlobalMintProgress.module.css';


type GlobalMintProgressProps = {
    progress:DataWithMeta<number>,
};

function GlobalMintProgress(props:GlobalMintProgressProps){
    useEffect(() => {
        registerListeningGlobalMintProgress();
        return () => unregisterListeningGlobalMintProgress();
    }, []);
    return <div className={styles['container']}>
        <div style={{width: `${props.progress.data ?? 0}%`}} className={styles['container']}></div>
    </div>
}


function mapStateToProps(state:any) : GlobalMintProgressProps{
    return {
        progress: globalMintProgressSelector(state)
    }
}



export default connect(mapStateToProps)(GlobalMintProgress)