import update from "immutability-helper";
import { success, loading, defaultDataWithMeta } from "utils/state";
import { DataWithMeta, Payload } from "types/state";
import { createReducer } from "state/configure";
import { DISMISS_TOPMOST_ALERT, GET_MY_ETHER_BALANCE_SUCCESS, GET_MY_FLIPS_SUCCESS, GET_MY_FLIP_COUNT_SUCCESS, LOGIN_PENDING, LOGIN_SUCCESS, LOGOUT, PRESENT_ALERT, UPDATE_GLOBAL_MINT_PROGRESS } from "./constants";
import { presentAlert, updateGlobalMintProgress } from "./actions";
import { AlertOptions } from "types/ui";
import { BigNumber } from "ethers";


export type State = {
    walletAddress: DataWithMeta<string>,
    modalAlerts:AlertOptions[],
    myFlips: DataWithMeta<any[]>,
    myFlipCount: DataWithMeta<number>,
    myEtherBalance: DataWithMeta<BigNumber>,
    globalMintProgress:DataWithMeta<number>
}

export interface PartialState {
    data: State;
}

const defaultState:State = {
    walletAddress: defaultDataWithMeta(),
    modalAlerts: [],
    myFlips: defaultDataWithMeta(),
    myEtherBalance: defaultDataWithMeta(),
    myFlipCount: defaultDataWithMeta(),
    globalMintProgress: defaultDataWithMeta()
}



const handlers = {
    [LOGIN_PENDING]: (state:State) =>
    update(state, { 
        walletAddress: loading()
    }),
    [LOGIN_SUCCESS]: (state:State, data:Payload<string>) =>
    update(state, { 
        walletAddress: success(data.payload)
    }),
    [GET_MY_FLIPS_SUCCESS]: (state:State, data:Payload<BigNumber[]>) =>
    update(state, { 
        myFlips: success(data.payload)
    }),
    [GET_MY_FLIP_COUNT_SUCCESS]: (state:State, data:Payload<number>) =>
    update(state, { 
        myFlipCount: success(data.payload)
    }),
    [PRESENT_ALERT]: (state:State, data:ReturnType<typeof presentAlert>) =>
    update(state, { 
        modalAlerts: { $push : [data.payload] } 
    }),
    [DISMISS_TOPMOST_ALERT]: (state:State) =>
    update(state, 
        { modalAlerts: { $splice: [[-1, 1]]  }
    }),
    [LOGOUT]:(state:State) =>
    update(state, 
        {
            walletAddress: {
                $set: defaultDataWithMeta()
            },
            myFlips: {
                $set: defaultDataWithMeta()
            }
        }
    ),
    [GET_MY_ETHER_BALANCE_SUCCESS]: (state:State, data:Payload<BigNumber>) =>
    update(state, { 
        myEtherBalance: success(data.payload)
    }),
    [UPDATE_GLOBAL_MINT_PROGRESS]: (state:State, data:ReturnType<typeof updateGlobalMintProgress>) =>
    update(state, { 
        globalMintProgress: success(data.payload.progress)
    }),

    

}
export const dataReducer = createReducer(defaultState, handlers)

