import { ethers } from "ethers";
import { CONTRACT_ABI, CONTRACT_ADDRESS } from "./constants";

export let web3Provider:ethers.providers.Web3Provider|null = generateProvider();

function generateProvider(){
    const ethereum = getEthereum();
    return ethereum ? new ethers.providers.Web3Provider(ethereum) : null;
}

export function getEthereum(){
    return (window as any).ethereum;
}

export function regenerateProvider(){
    web3Provider = generateProvider();
}

export function getContract(withSigner = false){
    if(withSigner){
        return (new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, web3Provider!)).connect(web3Provider!.getSigner());
    }
    return (new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, web3Provider!));
}

export async function checkShouldAutoLogin(){
    const addresses = await web3Provider?.listAccounts(); 
    if(addresses?.length){
        return true;
    }
    return false;
}

let _listeningMints = 0;

export function registerListeningGlobalMintProgress(){
    _listeningMints++;
    if(_listeningMints == 1){
        getContract().on("Transfer", onTransfer);
    }
}

export function unregisterListeningGlobalMintProgress(){
    _listeningMints--;
    if(_listeningMints == 0){
        getContract().off("Transfer", onTransfer);
    }
}

function onTransfer(from:string, to:string, value:string){
    debugger;
}

export function isPublicSaleInProgress(){
    return getContract().isPublicSaleInProgress() as Promise<boolean>;
}

export function isPrivateSaleInProgress(){
    return getContract().isPrivateSaleInProgress() as Promise<boolean>;
}

export function isRevealed(){
    return getContract().isRevealed() as Promise<boolean>;
}

