import { BigNumber, ethers } from 'ethers';
import { CHAIN_ID, PRICE_PER_TOKEN_IN_ETHER } from 'interop/web3/constants';
import { getContract, regenerateProvider, web3Provider } from 'interop/web3/ethereum';
import { takeEvery, all, call, put, select } from 'redux-saga/effects'
import { error, success } from 'utils/saga';
import { getMyEtherBalance, getMyFlipCount, getMyFlips, mint, switchToCorrectNetwork } from './actions';
import { GET_MY_ETHER_BALANCE_FAILURE, GET_MY_ETHER_BALANCE_PENDING, GET_MY_ETHER_BALANCE_SUCCESS, GET_MY_FLIPS_FAILURE, GET_MY_FLIPS_PENDING, GET_MY_FLIPS_SUCCESS, GET_MY_FLIP_COUNT_FAILURE, GET_MY_FLIP_COUNT_PENDING, GET_MY_FLIP_COUNT_SUCCESS, LOGIN_FAILURE, LOGIN_PENDING, LOGIN_SUCCESS, MINT_FAILURE, MINT_PENDING, MINT_SUCCESS, SWITCH_TO_CORRECT_NETWORK_FAILURE, SWITCH_TO_CORRECT_NETWORK_PENDING, SWITCH_TO_CORRECT_NETWORK_SUCCESS } from './constants'
import { INCORRECT_CHAIN, NO_PROVIDER_FOUND } from './errors';
import { login } from 'state/actions';
import { walletAddressSelector } from './selectors';



export default function* rootSaga(){
    yield all([
        takeEvery(LOGIN_PENDING, loginSaga),
        takeEvery(SWITCH_TO_CORRECT_NETWORK_PENDING, switchToCorrectNetworkSaga),
        takeEvery(MINT_PENDING, mintSaga),
        takeEvery(GET_MY_ETHER_BALANCE_PENDING, getMyEtherBalanceSaga),
        takeEvery(GET_MY_FLIPS_PENDING, getMyFlipsSaga),
        takeEvery(GET_MY_FLIP_COUNT_PENDING, getMyFlipCountSaga),
    ])
}

function* loginSaga() {
    try{
        const provider = web3Provider;
        if(!provider){
            throw NO_PROVIDER_FOUND;
        }
        const currentChainId = (yield provider.getNetwork()).chainId;
        if(currentChainId !== CHAIN_ID){
            yield put(switchToCorrectNetwork());
            throw INCORRECT_CHAIN;
        }
        yield provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const address:string = yield signer.getAddress();
        yield success(LOGIN_SUCCESS, address);
        yield put(getMyEtherBalance());
        yield put(getMyFlipCount());
    }catch(e){
        yield error(LOGIN_FAILURE, e);
    } 
}

function* getMyEtherBalanceSaga() {
    try{
        const provider = web3Provider;
        if(!provider){
            throw NO_PROVIDER_FOUND;
        }
        const currentChainId = (yield provider.getNetwork()).chainId;
        if(currentChainId !== CHAIN_ID){
            yield put(switchToCorrectNetwork());
            throw INCORRECT_CHAIN;
        }
        const signer = provider.getSigner();
        const balance:BigNumber = yield signer.getBalance();
        yield success(GET_MY_ETHER_BALANCE_SUCCESS, balance);
    }catch(e){
        yield error(GET_MY_ETHER_BALANCE_FAILURE, e);
    } 
}

function* getMyFlipCountSaga() {
    try{
        const provider = web3Provider;
        if(!provider){
            throw NO_PROVIDER_FOUND;
        }
        const currentChainId = (yield provider.getNetwork()).chainId;
        if(currentChainId !== CHAIN_ID){
            yield put(switchToCorrectNetwork());
            throw INCORRECT_CHAIN;
        }
        const myAddress = (yield select(walletAddressSelector)).data;
        const myTokenCount = yield getContract().balanceOf(myAddress);
        yield success(GET_MY_FLIP_COUNT_SUCCESS, myTokenCount.toNumber());
    }catch(e){
        yield error(GET_MY_FLIP_COUNT_FAILURE, e);
    } 
}

function* getMyFlipsSaga() {
    try{
        const provider = web3Provider;
        if(!provider){
            throw NO_PROVIDER_FOUND;
        }
        const currentChainId = (yield provider.getNetwork()).chainId;
        if(currentChainId !== CHAIN_ID){
            yield put(switchToCorrectNetwork());
            throw INCORRECT_CHAIN;
        }
        const myAddress = (yield select(walletAddressSelector)).data;
        const myTokens = yield getContract().tokensOfOwner(myAddress);
        yield success(GET_MY_FLIPS_SUCCESS, myTokens);
    }catch(e){
        yield error(GET_MY_FLIPS_FAILURE, e);
    } 
}

function* switchToCorrectNetworkSaga() {
    try{
        const provider = web3Provider;
        if(!provider){
            throw NO_PROVIDER_FOUND;
        }
        yield provider.send("wallet_switchEthereumChain", [{chainId: `0x${CHAIN_ID.toString(16)}`}]);
        regenerateProvider();
        yield success(SWITCH_TO_CORRECT_NETWORK_SUCCESS);
        yield put(login())
    }catch(e){
        yield error(SWITCH_TO_CORRECT_NETWORK_FAILURE, e);
    } 
}

function* mintSaga(q:ReturnType<typeof mint>) {
    try{
        const provider = web3Provider;
        if(!provider){
            throw NO_PROVIDER_FOUND;
        }
        const contract = getContract(true);
        const etherValue = PRICE_PER_TOKEN_IN_ETHER * q.payload.amount;
        if(q.payload.merkleProof){
            const txn = yield contract.mintWhitelistNFTs(q.payload.amount, q.payload.merkleProof, { value: ethers.utils.parseEther(etherValue.toString()) });
            yield txn.wait(2);
        
        }else{
            const txn = yield contract.mintNFTs(q.payload.amount, { value: ethers.utils.parseEther(etherValue.toString()) });
            yield txn.wait(2);
        }
        yield put(getMyFlipCount());
        yield success(MINT_SUCCESS);
        yield put(login())
    }catch(e){
        yield error(MINT_FAILURE, e);
    } 
}
