import React, { useEffect, useState } from 'react';
import styles from './RequireEthereum.module.css';

type RequireEthereumProps = {
    children:any
};
function RequireEthereum(props:RequireEthereumProps){
    const [hasEthereum, setHasEthereum] = useState(!!window['ethereum']);
    useEffect(() => {
        setHasEthereum(!!window['ethereum']);

    }, [window['ethereum']]);
    useEffect(() => {
        if(window['ethereum']){
            if(window['ethereum'].chainId != '0xa4b1'){
                window['ethereum'].request({
                    method: "wallet_addEthereumChain",
                    params: [{
                        chainId: "0xa4b1",
                        rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                        chainName: "Arbitrum One",
                        nativeCurrency: {
                            name: "ETH",
                            symbol: "ETH",
                            decimals: 18
                        },
                        blockExplorerUrls: ["https://arbiscan.io/"]
                    }]
                });
            }
        }
    }, [window['ethereum']?.chainId]);
    if(hasEthereum){
        return props.children;
    }else{
        return <div style={{flex: 1}} className='center'>You need MetaMask to view this page</div>
    }
}

export default RequireEthereum;