import styles from './Button.module.css';

import React, { useState } from 'react';

function Button(props:React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>){
    const [pressed, setPressed] = useState(false);
    return <button {...props} onMouseDown={() => setPressed(true)} onMouseUp={() => setPressed(false)} 
    className={`${pressed ? styles['pressed'] : ''} ${styles['container']} ${props.className ?? ''}`}></button>
}

export default Button;