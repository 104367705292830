import React, { useState } from 'react';
import styles from './Header.module.css';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { login, logout } from 'state/actions';
import { pathNameSelector, walletAddressSelector } from 'state/selectors';
import { DataWithMeta } from 'types/state';
import { Link } from 'react-router-dom';
import { pathFriendlyName } from 'utils/route';
import ExpandButton from './buttons/ExpandButton';
import Button from './buttons/Button';
import WalletDetails from './web3/WalletDetails';

type HeaderProps = {
    myAddress?:DataWithMeta<string>;
    myBalance?:string,
    pathName:string
};
type HeaderDispatchProps = {
    connectWallet: () => void,
}

function Header(props:HeaderProps & HeaderDispatchProps){
    const [walletExpanded, setWalletExpanded] = useState(false);
    const friendlyPathName = pathFriendlyName(props.pathName);
    let additionalTitleFragment = <></>;
    if(friendlyPathName?.length){
        additionalTitleFragment = <span className={styles['friendly-path-name']}>{`${friendlyPathName}`}</span>
    }
    const expandButtonAdditionalClassName = walletExpanded ? 'expanded' : '';
    return <header className={styles.container}>
                <Link className={styles['logo-name-container']} to={'/'}>
                    <img alt='The Flip Project' className={styles['logo']} src="/images/logos/flip.png"></img>
                    <span className={`${styles['title-text']} hide-compact`}>The Flip Project</span>
                   
                </Link>
                {additionalTitleFragment}
                <div className="fill"></div>
                {
                    props.myAddress?.data?.length 
                        ?   <>
                                <span className={styles['my-address']}>{props.myAddress.data}</span>
                                <ExpandButton className={expandButtonAdditionalClassName} onClick={() => setWalletExpanded(!walletExpanded)} />
                                {
                                    walletExpanded ? <div className={styles['wallet-details-container']}>
                                        <WalletDetails /> 
                                    </div>: null
                                }
                            </>
                        :  <Button className={styles['connect-wallet']} onClick={() => props.connectWallet()}>Connect Wallet</Button>
                }
        </header>
}




function mapStateToProps(state:any) : HeaderProps{
    return {
        myAddress: walletAddressSelector(state),
        pathName: pathNameSelector(state)
    }
}


function mapDispatchToProps(dispatch:Dispatch) : HeaderDispatchProps{
    return {
        connectWallet: () => dispatch(login()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)