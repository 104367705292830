import { Action } from "redux";
import { alert } from "utils/ui/alert";

export const NO_PROVIDER_FOUND = "NO_PROVIDER_FOUND";
export const INCORRECT_CHAIN = "INCORRECT_CHAIN";


const ErrorDescription = {
    [NO_PROVIDER_FOUND]: "MetaMask not found. Please make sure you are running a browser that supports MetaMask and that MetaMask is installed.",
    [INCORRECT_CHAIN]: "You are on incorrect network. Please switch to Arbitrum on Metamask and try again."
}

type ActionWithError = Action<string> & { error: string };
export const errorHandlerMiddleware = () => (next:any) => (action:ActionWithError) => {
    console.log(action);
    if(action.type.endsWith('_FAILURE')){
        const errorKey = action.error;
        let description = 'An error occured';
        if(errorKey && ErrorDescription[errorKey]){
            description = ErrorDescription[errorKey];
        }else if(errorKey?.length){
            description = errorKey;
        }
        alert(description);
    }
    next(action);
}