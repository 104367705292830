import { Spinner } from 'components/Spinner';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialize } from 'utils/initialize';


type InitializerProps = {
    children:any
};
function Initializer(props:InitializerProps){
    const [didInitialize, setDidInitialize] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        initialize(dispatch).then(() => {
            setDidInitialize(true);
        })
    }, []);
    return didInitialize ? props.children : <Spinner />
}

export default Initializer;