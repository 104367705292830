import styles from './ExpandButton.module.css';

import Button from './Button';

function ExpandButton(props:React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>){
    return <Button {...props} className={`${styles['container']} ${props.className ?? ''}`}>
        <span className={styles['inner']}>∟</span>
    </Button>
}

export default ExpandButton;