import { presentAlert } from 'state/actions';
import { store } from 'state/configure';
import { AlertOptions, AlertAction } from 'types/ui';

const idToPromiseExecutorMap:{[key:number] : (data:any) => void} = {};

export const resolveActionForId = (id:number) => idToPromiseExecutorMap[id]

const newId = () => function*(){
    let current = 0;
        while(true){
    yield current++;
    }
}().next().value;


export function alert(content: string | AlertOptions){
    store.dispatch(presentAlert(createAlert(content)));
}

export function oops(description:string, then?: () => void){
    alert({title: 'Oops', description, actions: [
        {
            title: 'Okay',
            onPress: () => then && then()
        }
    ] });
}

export function genericError(){
    oops('Something went wrong.');
}

export function input<T = string>(title:string, description:string, placeholder?:string, existing?:string){
    const options = createAlert({
        title, description, inputOptions: { placeholder, existing }, alertType: 'input',
        actions: [
            SAVE, CANCEL
        ]
    });
    const id = newId();
    options.id = id;
    const promise = new Promise<T>((resolve, reject) => {
        idToPromiseExecutorMap[id] = resolve;
    });
    alert(options);
    return promise;
}

export function createAlert(content: string | AlertOptions){
    let options : AlertOptions = typeof content === 'string' ? {
        description: content
    } : content;
    if(!options.title){
        options.title = 'The Flip Project'
    }
    if(!options.actions || !options.actions.length){
        options.actions = [{
            title: 'Okay'
        }]
    }
    return options;
}

export const CANCEL: AlertAction = {
    title: 'Cancel'
}

export const SAVE: AlertAction = {
    title: 'Save',
    type: 'submit'
}