import { AlertOptions } from "types/ui";
import { DISMISS_TOPMOST_ALERT, GET_MY_ETHER_BALANCE_PENDING, 
    GET_MY_FLIPS_PENDING, GET_MY_FLIP_COUNT_PENDING, LOGIN_PENDING, LOGOUT, 
    MINT_PENDING, PRESENT_ALERT, SWITCH_TO_CORRECT_NETWORK_PENDING, 
UPDATE_GLOBAL_MINT_PROGRESS } from "./constants";

export function login(){
    return {
        type: LOGIN_PENDING
    }
}

export function getMyEtherBalance(){
    return {
        type: GET_MY_ETHER_BALANCE_PENDING
    }
}


export function getMyFlips(){
    return {
        type: GET_MY_FLIPS_PENDING
    }
}

export function getMyFlipCount(){
    return {
        type: GET_MY_FLIP_COUNT_PENDING
    }
}

export function mint(amount:number, merkleProof?:string[]){
    return {
        type: MINT_PENDING,
        payload: {
            amount, merkleProof
        }
    }
}

export function switchToCorrectNetwork(){
    return {
        type: SWITCH_TO_CORRECT_NETWORK_PENDING,
    }
}

export function presentAlert(alert: AlertOptions): any {
    return {
        type: PRESENT_ALERT,
        payload:{
            ...alert
        }
    }
}

export function dismissTopmostAlert(): any {
    return {
      type: DISMISS_TOPMOST_ALERT
    };
}

export function updateGlobalMintProgress(progress:number){
    return {
        type: UPDATE_GLOBAL_MINT_PROGRESS,
        payload: { progress }
    }
}

export function logout(){
    return {
        type: LOGOUT
    }
}