import { checkShouldAutoLogin, getEthereum } from "interop/web3/ethereum";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { login } from "state/actions";

let _didInit = false;

export async function initialize(dispatcher:Dispatch){
    if(_didInit){
        throw Error('Already initialized');
    }
    _didInit = true;
    const shouldLogin = await checkShouldAutoLogin();
    if(shouldLogin){
        dispatcher(login());
    }
    const ethereum = getEthereum();
    ethereum?.on('accountsChanged', function () {
        if(shouldLogin){
            dispatcher(login());
        }
    })
      
    ethereum?.on('chainChanged', function () {
        if(shouldLogin){
            dispatcher(login());
        }
    })
}