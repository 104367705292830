import styles from './Welcome.module.css';
import { Link } from 'react-router-dom';


export function Welcome(){
    return <div className={styles['container']}>
        <h2>Hi There!</h2>
        <br></br>
        <div className={styles['sections']}>
            <div className={styles['section']}>
                <h3>Minting</h3>
                <span>Tap below to mint your Flip NFTs!</span>
                <div className='fill'></div>
                <Link className={`${styles['section-button']} link-button`} to={'/mint'}>Mint Flip</Link>
            </div>
            <div className={`${styles['section']}`}>
                <h3>Collection</h3>
                <span>View and trade Flips on Trove marketplace.</span>
                <div className='fill'></div>
                <a className={`${styles['section-button']} link-button`} href={'https://trove.treasure.lol/collection/flip-project'}>View Collection</a>
            </div>
            <div className={`${styles['section']}`}>
                <h3>Your Inventory</h3>
                <span>View all the Flips that you own on Trove marketplace</span>
                <div className='fill'></div>
                <a className={`${styles['section-button']} link-button`} href={'https://trove.treasure.lol/collection/flip-project?tab=owned_tokens'}>View My Inventory</a>
            </div>
            <div className={`${styles['section']}`}>
                <h3>Calculator</h3>
                <span>Calculate abstract formula of a Flip category.</span>
                <div className='fill'></div>
                <Link className={`${styles['section-button']} link-button`} to={'/calculator'}>Go to calculator</Link>
            </div>
            
            
            
        </div>
    </div>

}