import { combineReducers, createStore, applyMiddleware, Middleware } from "redux";
import localforage from 'localforage'
import { PersistConfig, persistReducer } from "redux-persist";
import logger from 'redux-logger'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { dataReducer } from "state";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga";
import { errorHandlerMiddleware } from "./errors";

const middlewares:Middleware[] = [];

const persistConfig : PersistConfig<any> = {
    key: "flip",
    timeout: 1000,
    version: 1,
    storage: localforage,
    migrate: migrate as any,
    blacklist: ['data'],
    debug: true
} 

export const history = createBrowserHistory();


function migrate(state:PersistedGlobalState, versionKey:number){
  if(state && state._persist && (state._persist.version === versionKey)){
    return Promise.resolve(state); //same version, return the state.
  }
  return Promise.resolve({});
  
}

const reducerMap = {
    data: dataReducer,
    router: connectRouter(history)
}

export type GlobalState = {[P in keyof typeof reducerMap]: ReturnType<(typeof reducerMap)[P]>}
type PersistedGlobalState = GlobalState & {_persist?:{version?:number}};

export const rootReducer = combineReducers(reducerMap);
const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(routerMiddleware(history));
middlewares.push(errorHandlerMiddleware);

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export function createReducer<T>(initialState:T, handlers: {[key:string]: (s:any,a:any) => T}) {
    return function reducer(state = initialState, action: any) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}
