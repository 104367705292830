import styles from './WalletDetails.module.css';

import React, { useState } from 'react';
import Button from 'components/buttons/Button';
import { logout } from 'state/actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DataWithMeta } from 'types/state';
import { myFlipCountSelector, myFlipsSelector } from 'state/selectors';
import { Spinner } from 'components/Spinner';
import { BigNumber, ethers } from 'ethers';
import { Link } from 'react-router-dom';

type WalletDetailsProps = {
    myFlipCount:DataWithMeta<number>
}

type WalletDetailsDispatchProps = {
    disconnectWallet: () => void
}

function WalletDetails(props:WalletDetailsProps & WalletDetailsDispatchProps){
    return <div className={styles['container']}>
        {
            typeof props.myFlipCount.data !== 'undefined' 
                ? (<>
                    <span>You have <b>{props.myFlipCount.data.toString()}</b> Flip{props.myFlipCount.data === 1 ? '' : 's'}</span>
                    {
                        props.myFlipCount.data >= 1
                        ? <a target={'_blank'} className='inline-link' href={'https://trove.treasure.lol/collection/flip-project?tab=owned_tokens'}>Click here to view your inventory</a>
                        // ? <Link className='inline-link' to={'/inventory'}>Click here to view your inventory</Link>
                        : null
                    }
                </>)
                : <Spinner/>
        }
        <div className='fill'/>
        <Button onClick={() => props.disconnectWallet()}>Logout</Button>
    </div>
}


function mapStateToProps(state:any) : WalletDetailsProps{
    return {
        myFlipCount: myFlipCountSelector(state)
    }
}


function mapDispatchToProps(dispatch:Dispatch) : WalletDetailsDispatchProps{
    return {
        disconnectWallet: () => dispatch(logout())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(WalletDetails)