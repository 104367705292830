import React, { Component, CSSProperties } from "react";
import { connect } from "react-redux";
import { AlertAction, AlertOptions } from "types/ui";
import { resolveActionForId } from "utils/ui/alert";
import styles from './Alert.module.css';
import { alertSelector } from "state/selectors";
import { dismissTopmostAlert } from "state/actions";
import Button from "./buttons/Button";

type AlertProps = { alert?: AlertOptions }
type AlertDispatchProps = { dismiss: () => void }
type AlertState = { data?:any }
export default connect(mapStateToProps, mapDispatchToProps)(class Alert extends Component<AlertProps & AlertDispatchProps, AlertState>{

    
    dismissAndRunAction(action?: () => void, shouldSubmit = false){
        this.props.dismiss();
        if(action){
            action();
        }
        if(shouldSubmit){
            resolveActionForId(this.props.alert?.id!)(this.state.data);
        }
    }


    constructor(props:AlertProps){
        super(props as any);
        this.state = {
            data: props.alert?.inputOptions?.existing
        }
        document.body.addEventListener('keydown', e => {
            //this is deprecated but others don't catch ESC key.
            if (e.keyCode == 27) {
               this.dismissAndRunAction();
            }
        });
    }

    render(){
        if(!this.props.alert) return null;
        const buttons: JSX.Element[] = [];
        let actions: AlertAction[] = this.props.alert && this.props.alert.actions ? this.props.alert.actions : [];
        if(!actions.length){
            actions.push({
                title: 'Okay',
            });
        }
        let title = this.props.alert.title || 'The Flip Project';
        for (let index = 0; index < actions.length; index++) {
            const element = actions[index];
            const styleList : any[] = [styles.buttonText];
            if(element.type == 'danger'){
                styleList.push(styles.dangerTitle);
            }
            if(element.type != 'danger'){
                buttons.push(<Button className={styles['button']} onClick={() => this.dismissAndRunAction(element.onPress, element.type == 'submit')}  key={'button' + index}>
                    <span key={'buttonText' + index} className={styles['button-text']}>{element.title}</span>
                </Button>)
            }else{
                buttons.push(<Button className={`${styles['button']} ${styles['danger-button']}`} onClick={() => this.dismissAndRunAction(element.onPress, element.type == 'submit')}  key={'button' + index}>
                            <span key={'buttonText' + index} className={styles['button-text']}>{element.title}</span>
                        </Button>)
            }
            
        }
        return(
            <div className={`${styles['page']} ${this.props.alert.alertType == 'danger' ? styles['alert-page'] : ''}`}>
                <div className={`fadein scaleup ${styles['alert-box']}`}>
                    <span className={`${styles['title']} ${this.props.alert.alertType == 'danger' ? styles['danger-title'] : ''}`}>{title}</span>
                    <span className={styles['description']}>{this.props.alert.description}</span>
                    {
                        this.props.alert.alertType == 'input' ?
                        <div className={styles['input-container']}>
                            <input className={styles['text-field']} type='text' onChange={e => this.setState({data: e.target.value})} placeholder={this.props.alert.inputOptions?.placeholder}>
                                {this.props.alert.inputOptions?.existing}
                            </input>
                        </div>
                        : null
                    }
                    <div className={styles['buttons']}>
                        {buttons}
                    </div>
                </div>
            </div>
        )
    }
})


function mapStateToProps(state:any){
    const alert = alertSelector(state)
    return {
        alert
    }
}

function mapDispatchToProps(dispatch: any) : AlertDispatchProps {
    return {
        dismiss: () => dispatch(dismissTopmostAlert()),
    }
}
