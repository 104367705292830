import { copyLog } from 'utils/log';
import Button from './buttons/Button';
import styles from './Footer.module.css';


export function Footer(){
    return <footer className={styles['container']}>
        <div className={styles['links']}>
            <a className={styles['link']} href='https://discord.gg/g9zAp5G9' target="_blank">
                <img className={styles['logo']} src="/images/logos/discord.png"></img>
                <p className={styles['link-title']}>Discord</p>
            </a>
            <a className={styles['link']} href='https://twitter.com/project_flip' target="_blank">
                <img className={styles['logo']} src="/images/logos/twitter.png"></img>
                <p className={styles['link-title']}>Twitter</p>
            </a>
            {/* <a className={styles['link']} href='https://opensea.io/collection/flip-project' target="_blank">
                <img className={styles['logo']} src="/images/logos/opensea.png"></img>
                <p className={styles['link-title']}>OpenSea</p>
            </a> */}
            <a className={styles['link']} href='https://medium.com/@flipproject' target="_blank">
                <img className={styles['logo']} src="/images/logos/medium.png"></img>
                <p className={styles['link-title']}>Medium</p>
            </a>
        </div>
        <sub className={styles['disclaimer']}>
            <span>Flip Project is a conceptual art experiment, seeking to disrupt the perception of value in Web 3.0 through a 100-days community-led art performance.</span>
            <br></br>
            <span>HODLers vs Flippers. Who will prevail?</span>
            <br></br>
            <span>The formulas on our NFTs neither indicate nor dictate any sort of monetary value. The formula results are simple numbers with no currency units. The formula is an art performance tool, with no absolute purpose. Our collectors are free to interpret the formula in any way they want.</span>
            <br></br><Button className={styles['copy-tx-log-button']} onClick={() => copyLog()}>Copy Transaction Log</Button>
        </sub>
    </footer>
}