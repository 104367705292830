import React from 'react';
import styles from './Calculator.module.css';

type CalculatorProps = {

};
function Calculator(props:CalculatorProps){
    return <div className={styles['container']}>
        <iframe src='/pages/calculator/index.html'/>
    </div>
}

export default Calculator;