import { extend, Spec } from "immutability-helper";
import { DataWithMeta, MergeLoadingAndDataSpec, MergeLoadingSpec, MetaDictionary } from "../types/state";

export function defaultDataWithMeta(){
    return {
        meta: {
            loading: false
        }
    }
}

export function success<T, M>(data: T, additionalMetadata?: M): any {
    return setDataWithMeta(data, false, additionalMetadata);
}
const UNSET = "__$unset";
function setDataWithMeta<T = any, M = {}>(
  data: T | typeof UNSET | undefined,
  loading: boolean,
  additionalMetadata?: any
): Spec<DataWithMeta<T, Spec<M>>> {
  if (data === UNSET) {
    return {
      $unset: ["data"],
      meta: {
        $merge: {
          loading: false,
          loadedAt: new Date(),
          ...additionalMetadata,
        }
      }
    };
  }

  if (typeof data === "undefined") {
    const loadingMergeKey = loading
      ? {
          loading,
          ...additionalMetadata
        }
      : {
          loading,
          loadedAt: new Date(),
          ...additionalMetadata
        };

    return {
      meta: {
        $merge: loadingMergeKey
      }
    };
  }
  const additionalMetadataSpec:Spec<any> = {};
  if (additionalMetadata) {
    const keys = Object.keys(additionalMetadata);
    keys.forEach(key => {
      additionalMetadataSpec[key] = {
        $set: additionalMetadata[key]
      };
    });
  }
  return {
    data: {
      $set: data
    },
    meta: {
      loading: {
        $set: loading
      },
      $unset: ['error'],
      ...additionalMetadataSpec
    }
  };
}

export function loading(
  loading: boolean = true,
  additionalMetadata?: any
): any {
  return setDataWithMeta(undefined, loading, additionalMetadata);
}

export function loadingKey(
  key: number | string,
  loading: boolean = true,
  additionalMetadata?: any
): MergeLoadingSpec {
  return ({
    $mergeDataWithMeta: {
      [key as string | number]: { meta: { loading, ...additionalMetadata } }
    }
  } as any) as MergeLoadingSpec;
}

export function fail(error?: any) {
  return loading(false, { error });
}

export function failKey(key: number | string, error: any) {
  return loadingKey(key, false, { error });
}

export function successKey<T>(
  key: number | string,
  data: T
): MergeLoadingAndDataSpec<T> {
  return ({
    $mergeDataWithMeta: {
      [key as string | number]: {
        meta: { 
          loading: false,
          loadedAt: new Date(),
         },
        data
      }
    }
  } as any) as MergeLoadingAndDataSpec<T>;
}

export function deleteKey(
  key: number | string
): MergeLoadingAndDataSpec {
  return ({
    $mergeDataWithMeta: {
      $unset: key
    }
  } as any) as MergeLoadingAndDataSpec;
}


extend("$mergeDataWithMeta", function(value, original) {
    function mergeRecursive(first: any, second: any) {
      if (typeof second !== "object") {
          return second;
      }
      if (second instanceof Date) {
          return second;
      }
      if (second instanceof Array) {
          return second;
      }
      const obj = Object.assign({}, first);
      // if (!Object.keys(obj).length && !Object.keys(second).length) {
      //     // return obj;
      // }
      for (const key in second) {
          obj[key] = mergeRecursive(obj[key], second[key]);
      }
      return obj;
    }
    const merged = mergeRecursive(original, value);
    return merged;
});

export function loadedNow<T>(item:T):DataWithMeta<T>{
  return {
    data: item,
    meta:{
      loading: false,
    }
  }
}


export function mergeEntities<T extends {id:string}>(entites:T[]){
  return { 
    $merge: entites.reduce((map, entity) => {
      map[entity.id] = loadedNow(entity);
      return map;
    }, {} as MetaDictionary<T>)
  }
}
